<script>
import Layout from "../../layouts/main";
import StudentIndexLayout from "../../layouts/studentIndexLayout";
import  ConfirmActionDialog  from "@/components/ConfirmActionDialog";
import errorHandler from "@/helpers/errorHandler";
import ExportExcel from '../../../components/exportExcel.vue';

/**
 * Starter component
 */
export default {
  page: {
    title: "Students list",
  },
  head() {
    return {
      title: `Students`,
    };
  },
  components: {
    Layout,
    ConfirmActionDialog,
    StudentIndexLayout,
    ExportExcel
  },
  async created() {
    await this.$store.dispatch("student/clearList")
  },
  computed: {
    tableFields() {
      return this.fields.filter(f => f.selected);
    },
    students() {
      return this.$store.getters["student/allStudents"];
    },
    rows() {
      return this.students.length;
    }
  },
  methods: {
    // async getStudents(){
    //   await this.$store.dispatch("student/getStudentList").then(()=>{}).catch( err =>{
    //     // errorHandler.methods.errorMessage(err);
    //     console.log("No items")
    //   })
    // },
    async getSearchStudents(){
      if(this.filter.length < 4){
        errorHandler.methods.errorValue("Enter at least 4 chars")
        return;
      }
      await this.$store.dispatch("student/getSearchStudentList",this.filter).then(()=>{}).catch( err =>{
        // errorHandler.methods.errorMessage(err);
        console.log("No items")
      })
    },
    RemoveRecord:function(){
     this.$store.dispatch("student/deleteStudent",this.recordId).then((token)=>{
      errorHandler.methods.successful('Item deleted successfully!')
     }).catch(err =>{
      errorHandler.methods.errorMessage(err);
     });
     this.$bvModal.hide('deleteRecord')
    },
     showDeleteModal(id) {
            this.$bvModal.show('deleteRecord')
             this.recordId = id
    },
    viewProfile(record,index){
      this.$router.push('/students/' + record.id + '/details')
    },
    async approveStudent(id){
      await this.$store.dispatch('student/approveStudent',id).then( res =>{
        errorHandler.methods.successful('Student approved');
      }).catch( err =>{
        errorHandler.methods.error(err);
      })
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    }
  },
  data: () => ({    totalRows:0,
    recordId: null,
    perPage: 20,
    currentPage: 1,
    pageOptions: [10, 20, 30],
    filter: "",
    sortBy: "name",
    sortDesc: false,
    filterOn: ["id","name","phone_number"],
    fields: [
      {
        key: "id",
        label: "Student ID",
        sortable: true,
        selected: true
      },
      {
        key: "name",
        label: "Name",
        sortable: true,
        selected: true
      },
      {
        key: "user_name",
        label: "Username",
        sortable: true,
        selected: true
      },
      {
        key: "phone_number",
        label: "Phone",
        sortable: true,
        selected: true
      },
      {
        key: "action",
        label: "",
        sortable: false,
        selected: true,
        disabled: true
      }
    ]
  })
};
</script>

<template>
  <Layout>
    <StudentIndexLayout>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Display&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;Records
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                    <!-- <b-button variant="primary" class="btn-sm mr-1" @click="getStudents">
                      <i class="mdi mdi-refresh"></i>
                      Load Students
                    </b-button> -->
                    <b-button variant="success" class="btn-sm mr-2" @click="getSearchStudents">
                      <i class="mdi mdi-magnify"></i>
                      Search Students
                    </b-button>
                    <export-excel :jsonData="students" v-if="$store.getters['user/role'] == 0"></export-excel>
                  <label class="d-inline-flex align-items-center mt-2">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                  <b-dropdown
                    id="dropdown-offset"
                    offset="250"
                    variant="link"
                    class="btn-icon-only"
                  >
                    <template #button-content>
                      <i class="fas fa-th-large iconGray"></i>
                    </template>
                    <b-dropdown-form>
                      <b-form-checkbox
                        v-for="(field, index) in fields"
                        :key="index"
                        class="mb-3"
                        v-model="field.selected"
                        :disabled="field.disabled"
                        >{{ field.label }}</b-form-checkbox
                      >
                    </b-dropdown-form>
                  </b-dropdown>
                </div>
              </div>
              <!-- End search -->
            </div>
            <b-table
              table-class="table table-centered w-100 table-hover"
              thead-tr-class="bg-light"
              tbody-tr-class="clickableRow"
              :items="students"
              :fields="tableFields"
              responsive="sm"
              :per-page="perPage"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
              @row-clicked="viewProfile"
            >
            <template v-slot:cell(phone_numbers)="data">
              {{ data.item.phone_number }}
            </template>
          <template v-slot:cell(action)="data">
                <b-dropdown
                  class="btn-icon-onl"
                  right
                  toggle-class="arrow-none btn-light btn-sm"
                  v-if="$store.getters['user/role'] == 0"
                >
                  <template v-slot:button-content>
                    <i class="mdi mdi-dots-horizontal iconGray"></i>
                  </template>

                  <b-dropdown-item
                    :to="'/students/' + data.item.id + '/edit'"
                  >
                    <i
                      class="mdi mdi-pencil mr-2 text-muted font-18 vertical-middle iconGray"
                    ></i
                    >Edit
                  </b-dropdown-item>
                  <b-dropdown-item
                 v-on:click="showDeleteModal(data.item.id)"
                  ><i
                    class="mdi mdi-delete mr-2 text-muted font-18 vertical-middle iconGray"
                  ></i
                  >Delete
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="(filter == '' )?rows:totalRows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="deleteRecord" size="md" title="Warning Action">
        <ConfirmActionDialog/>
        <template #modal-footer>
          <b-button variant="outline-success" @click="RemoveRecord()">Delete</b-button>
          <b-button variant="outline-danger" @click="$bvModal.hide('deleteRecord')">Cancel</b-button>
        </template>
    </b-modal>
    </StudentIndexLayout>
  </Layout>
</template>
